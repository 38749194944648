import * as React from 'react';

import { ArrowsClockwise, User } from '@phosphor-icons/react';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import { Link, PageTitle } from '@/components/common';
import { Period } from '@/components/ui';

type MenuLink = { title: string; path: string; icon: React.ReactNode };

const ACCOUNT_SETTINGS_MENU: MenuLink[] = [
  { title: 'Profile', path: '/account/settings', icon: <User className='w-5 sm:auto' size={28} /> },
  { title: 'Cycle', path: '/account/settings/cycle', icon: <Period className='w-5 sm:auto' size={28} /> },
  {
    title: 'Subscription',
    path: '/account/settings/subscription',
    icon: <ArrowsClockwise className='w-5 sm:auto' size={28} />,
  },
];

export interface AccountLayoutProps {
  showTabs: boolean;
  children: React.ReactNode;
}

export const AccountLayout = React.memo<AccountLayoutProps>(({ showTabs, children }) => {
  const router = useRouter();

  return (
    <div>
      <div className='flex flex-col text-center'>
        <PageTitle>{showTabs ? 'Settings' : 'Account'}</PageTitle>
        {showTabs && (
          <div
            className={clsx(
              'flex w-full mb-8 max-w-3xl mx-auto relative overflow-x-auto hide-scrollbar border-b-khaki-3 border-b-1',
              'before:border-b before:border-b-khaki-3 before:inset-x-0 before:bottom-0 before:absolute before:-z-10',
            )}
          >
            {ACCOUNT_SETTINGS_MENU.map((item) => (
              <Link
                className={clsx(
                  'flex gap-2 items-center grow justify-center shrink-0 pb-4 gap-y-2 border-b border-b-khaki-3 hover:text-purple-3',
                  router.pathname === item.path ? 'text-purple-3 border-b-purple-3' : '',
                )}
                href={item.path}
                key={item.path}
              >
                {item.icon}
                {item.title}
              </Link>
            ))}
          </div>
        )}
      </div>
      <div className='px-5'>{children}</div>
    </div>
  );
});

AccountLayout.displayName = 'AccountLayout';
