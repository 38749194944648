/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';

import { ArrowSquareOut, Info, ArrowClockwise } from '@phosphor-icons/react';
import { User } from '@prisma/client';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Tooltip } from 'react-tooltip';

import { Link, StructuredModal, SubscriptionStatusIndicator } from '@/components/common';
import { Spinner } from '@/components/ui';
import { capitalize } from '@/utils/string';
import { trpc } from '@/utils/trpc';

type UserAdminInfoModalProps = { user: User };

export const UserAdminInfoModal = React.memo<UserAdminInfoModalProps>(({ user }) => {
  const { data, isLoading } = trpc.users.adminInfo.useQuery({ id: user.id });

  const { mutate, isLoading: isRefreshLoading } = trpc.epixel.getUserDetails.useMutation({
    onSuccess: (result) => {
      if (result?.epixelId && result?.epixelUsername && data) {
        data.epixelUsername = result.epixelUsername;
      }
    },
  });

  return (
    <StructuredModal
      className='min-w-[768px]'
      footer={
        <div className='flex w-full justify-between'>
          <div>Created At: {format(user.createdAt, 'MM/dd/yyyy')}</div>
          <div>Last Updated: {format(user.updatedAt, 'MM/dd/yyyy H:m:saaa')}</div>
        </div>
      }
      title={`User Info - ${user.email}`}
    >
      {isLoading && <Spinner />}
      {data && (
        <div className='grid grid-flow-row grid-cols-2 gap-4'>
          <div>
            <label className='font-lato font-bold block mb-2 text-sm'>General</label>
            <div>
              <div>
                Time Zone: {data.timeZone}{' '}
                <Info className='w-4 h-4 inline cursor-pointer' data-tooltip-id='time-zone' />
              </div>
              <Tooltip className='z-10 max-w-sm' closeOnScroll id='time-zone'>
                Content and predictions are more accurate if the user's time zone is set to their correct region.
              </Tooltip>
            </div>

            <div className='my-4 h-[2px] bg-khaki-2' />

            <label className='font-lato font-bold block mb-2 text-sm'>
              Auth Methods <Info className='w-4 h-4 inline cursor-pointer' data-tooltip-id='auth-methods' />
            </label>
            <Tooltip className='z-10 max-w-sm' closeOnScroll id='auth-methods'>
              Users may have several Auth methods. The first listed is always the original Auth method they used to sign
              up. If a user experiences issues with a specific Auth method/provider, confirm the user is using the
              correct email address. If the user is using the wrong method/provider, they will need to use one they have
              previously used to log in.
            </Tooltip>
            <div className='flex gap-4'>
              {data.authMethods.map((authMethod, i) => {
                return <div key={authMethod}>{i === data.authMethods.length - 1 ? authMethod : `${authMethod}`}</div>;
              })}
            </div>

            <div className='my-4 h-[2px] bg-khaki-2' />

            <label className='font-lato font-bold block mb-2 text-sm'>
              Subscription Info{' '}
              <SubscriptionStatusIndicator className='ml-2' status={user.isSubscriber ? 'active' : ''} />
            </label>
            <div>
              <Link
                className='flex gap-2 items-center'
                href={`https://app.revenuecat.com/customers/1865bfbe/${user.id}`}
              >
                RevenueCat <ArrowSquareOut className='w-4 h-4' />
              </Link>
              <Link
                className='flex gap-2 items-center'
                href={`https://dashboard.stripe.com/${process.env.NODE_ENV === 'development' ? 'test/' : ''}customers/${
                  user.stripeCustomerId
                }`}
              >
                Stripe <ArrowSquareOut className='w-4 h-4' />
              </Link>
            </div>
          </div>

          <div>
            <label className='font-lato font-bold block mb-2 text-sm'>
              Phase Info <Info className='w-4 h-4 inline cursor-pointer' data-tooltip-id='phase-info' />
            </label>
            <Tooltip className='z-10 max-w-sm' closeOnScroll id='phase-info'>
              Users will be in a phase for a set number of days based on their cycle duration. You may see "phaseday
              number" and "raw phaseday number" are different - this is expected behavior.
            </Tooltip>
            <div className=''>
              {Object.keys(data.phaseInfo).map((key) => {
                return (
                  <div key={key}>
                    {capitalize(key.replace(/([a-z])([A-Z])/g, '$1 $2'))}
                    {key === 'rawPhasedayNumber' && (
                      <>
                        {' '}
                        <Info className='w-4 h-4 inline cursor-pointer' data-tooltip-id='raw-phaseday-number' />
                      </>
                    )}
                    {key === 'phasedayNumber' && (
                      <>
                        {' '}
                        <Info className='w-4 h-4 inline cursor-pointer' data-tooltip-id='phaseday-number' />
                      </>
                    )}
                    :
                    <>
                      {' '}
                      {/* @ts-ignore */}
                      <span>{data.phaseInfo[key]}</span>
                    </>
                  </div>
                );
              })}
            </div>
            <Tooltip className='z-10 max-w-sm' closeOnScroll id='phaseday-number'>
              The "phaseday number" is the number of days since the user's cycle started - adjusted to be between 1 and
              28. This is used for content curation and cycle predictions.
            </Tooltip>
            <Tooltip className='z-10 max-w-sm' closeOnScroll id='raw-phaseday-number'>
              The "raw phaseday number" is the *actual* number of days since the user's cycle started since their start
              date. The current minumum/maximum is 21/40 days respectively. This is used to make more accurate
              calculations for content curation, cycle predictions, and changes to user accounts.
            </Tooltip>

            <div className='my-4 h-[2px] bg-khaki-2' />

            <label className='font-lato font-bold block mb-2 text-sm'>Cycle Info</label>
            <div>
              <div>Days Left: {data.daysLeft}</div>
              <div>Duration: {data.cycleLunar ? <>** lunar cycle **</> : <>{data.cycleDuration} days</>}</div>
              <div>
                Start Date: {data.cycleLunar ? <>** lunar cycle **</> : <>{format(data.cycleStart, 'MM/dd/yyyy')}</>}
              </div>
            </div>
            <div className='my-4 h-[2px] bg-khaki-2' />
            <label className='font-lato font-bold block mb-2 text-sm'>
              Epixel Info{' '}
              <ArrowClockwise
                className={clsx('w-4 h-4 inline cursor-pointer', isRefreshLoading && 'animate-spin')}
                onClick={() => mutate({ id: user.id })}
              />
            </label>
            <div>
              <div>Username: {data.epixelUsername}</div>
            </div>
          </div>
        </div>
      )}
    </StructuredModal>
  );
});

UserAdminInfoModal.displayName = 'UserAdminInfoModal';
