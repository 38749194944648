/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';

import { useAuth } from '@clerk/nextjs';
import { zodResolver } from '@hookform/resolvers/zod';
import { SignOut } from '@phosphor-icons/react';
import { Prisma, User } from '@prisma/client';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { z } from 'zod';

import { BirthdayZodiacInput } from '@/components/account';
import { Button, Input, Select, Checkbox, PhoneInput } from '@/components/ui';
import { useAnalyticsDispatch } from '@/context/analytics';
import { SITE_URL } from '@/lib/constants';
import { PreferredGoals } from '@/types/28';
import { ERROR } from '@/utils/form';
import { trpc } from '@/utils/trpc';

import { ZodiacSigns } from '../BirthdayZodiacInput/ZodiacIcons';

export type AccountProfileUpdateFormValues = Pick<
  User,
  'birthday' | 'firstName' | 'lastName' | 'name' | 'phone' | 'preferredPeriodTracker' | 'zodiacSign'
> & { preferredGoals: Prisma.InputJsonValue };

// @ts-ignore
const schema: z.ZodType<AccountProfileUpdateFormValues> = z.lazy(() =>
  z.object({
    birthday: z.date().nullable(),
    firstName: z.string().min(1, { message: ERROR.REQUIRED }),
    lastName: z.string().min(1, { message: ERROR.REQUIRED }),
    name: z.string().min(1, { message: ERROR.REQUIRED }),
    phone: z.string().length(10, { message: ERROR.VALID_PHONE }).nullable(),
    preferredGoals: z.any(),
    preferredPeriodTracker: z.string().nullable(),
    zodiacSign: z.string().nullable(),
  }),
);

export interface AccountProfileUpdateFormProps {
  dbUser: User;
}

export const AccountProfileUpdateForm = React.memo<AccountProfileUpdateFormProps>(({ dbUser: user }) => {
  const { signOut } = useAuth();
  const router = useRouter();
  const analyticsDispatch = useAnalyticsDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<AccountProfileUpdateFormValues>({
    defaultValues: {
      birthday: user.birthday ? new Date(user.birthday) : null,
      zodiacSign: user.zodiacSign,
      firstName: user.firstName || user.name,
      lastName: user.lastName,
      phone: user.phone,
      name: user.name,
      preferredGoals: user.preferredGoals ? user.preferredGoals : {},
      preferredPeriodTracker: user.preferredPeriodTracker,
    },
    resolver: zodResolver(schema),
  });

  const { mutateAsync } = trpc.users.update.useMutation({
    onMutate: () => toast.loading('Saving...'),
    onSuccess: () => {
      toast.remove();
      toast.success(`Account updated`);
    },
    onError: (error) => {
      toast.remove();
      toast.error(error.message);
    },
  });

  const onSubmit = handleSubmit((values) =>
    mutateAsync({
      id: user.id,
      ...values,
      birthday: values.birthday ? values.birthday.toISOString() : null,
    }).then((user) => {
      analyticsDispatch({ type: 'EXPLICIT_IDENTIFY', payload: { dbUser: user.data } });
      router.push('/account');
    }),
  );

  return (
    <div className='mx-auto max-w-3xl'>
      <div className='bg-khaki-1 rounded-lg p-5 sm:p-8'>
        <form onSubmit={onSubmit}>
          <div className='flex flex-col gap-y-4 sm:flex-row sm:gap-x-4 [&_>_div]:flex-1'>
            <Input errors={errors} label='First Name' {...register('firstName')} />
            <Input errors={errors} label='Last Name' {...register('lastName')} />
          </div>
          <div className='flex flex-col gap-y-4 sm:flex-row sm:gap-x-4 [&_>_div]:flex-1'>
            <PhoneInput
              defaultValue={user.phone}
              errors={errors}
              label='Phone Number'
              name='phone'
              // @ts-ignore
              onChange={(phoneNumber) => setValue('phone', phoneNumber, { shouldDirty: true })}
            />
            <BirthdayZodiacInput
              className='mb-7 sm:mb-0'
              defaultDate={user.birthday ? new Date(user.birthday) : undefined}
              defaultSign={user.zodiacSign ? (user.zodiacSign as ZodiacSigns) : undefined}
              onChange={(date, sign) => {
                setValue('birthday', date, { shouldDirty: true });
                setValue('zodiacSign', sign, { shouldDirty: true });
              }}
            />
          </div>
          <Select
            defaultValue={
              user.preferredPeriodTracker
                ? { text: user.preferredPeriodTracker, value: user.preferredPeriodTracker }
                : null
            }
            label='What app do you use to track your period?'
            onChange={(optionValue) => setValue('preferredPeriodTracker', optionValue, { shouldDirty: true })}
            options={[
              { text: 'Natural Cycles', value: 'natural-cycles' },
              { text: 'Flo', value: 'flo' },
              { text: 'Eve', value: 'eve' },
              { text: 'Clue', value: 'clue' },
              { text: 'Manual', value: 'manual' },
              { text: 'None', value: 'none' },
              { text: 'Other', value: 'other' },
            ]}
          />
          <div className='flex flex-col gap-y-4'>
            <div className='font-lato font-bold block mb-2 text-sm'>What are your goals?</div>
            <Checkbox
              defaultChecked={(user.preferredGoals as Record<keyof typeof PreferredGoals, boolean>)?.['build-muscle']}
              errors={errors}
              label={PreferredGoals['build-muscle']}
              {...register('preferredGoals.build-muscle')}
            />
            <Checkbox
              defaultChecked={(user.preferredGoals as Record<keyof typeof PreferredGoals, boolean>)?.['lose-weight']}
              errors={errors}
              label={PreferredGoals['lose-weight']}
              {...register('preferredGoals.lose-weight')}
            />
            <Checkbox
              defaultChecked={
                (user.preferredGoals as Record<keyof typeof PreferredGoals, boolean>)?.['maintain-physique']
              }
              errors={errors}
              label={PreferredGoals['maintain-physique']}
              {...register('preferredGoals.maintain-physique')}
            />
            <Checkbox
              defaultChecked={
                (user.preferredGoals as Record<keyof typeof PreferredGoals, boolean>)?.['trying-to-get-pregnant']
              }
              errors={errors}
              label={PreferredGoals['trying-to-get-pregnant']}
              {...register('preferredGoals.trying-to-get-pregnant')}
            />
            <Checkbox
              defaultChecked={
                (user.preferredGoals as Record<keyof typeof PreferredGoals, boolean>)?.[
                  'reduce-pains-related-to-my-period'
                ]
              }
              errors={errors}
              label={PreferredGoals['reduce-pains-related-to-my-period']}
              {...register('preferredGoals.reduce-pains-related-to-my-period')}
            />
            <Checkbox
              defaultChecked={
                (user.preferredGoals as Record<keyof typeof PreferredGoals, boolean>)?.['increase-midfulness']
              }
              errors={errors}
              label={PreferredGoals['increase-midfulness']}
              {...register('preferredGoals.increase-midfulness')}
            />
            <Checkbox
              defaultChecked={
                (user.preferredGoals as Record<keyof typeof PreferredGoals, boolean>)?.['build-healthier-eating-habits']
              }
              errors={errors}
              label={PreferredGoals['build-healthier-eating-habits']}
              {...register('preferredGoals.build-healthier-eating-habits')}
            />
          </div>
          <div className='flex gap-x-6 mt-2 -mx-5 sm:-mx-8 -mb-5 sm:-mb-8 px-5 sm:px-8 py-5 rounded-bl-lg bg-khaki-2-3 justify-between'>
            <Button
              disabled={isSubmitting || !isDirty}
              state={isSubmitting ? 'waiting' : undefined}
              type='submit'
              variant='primary'
            >
              Save
            </Button>
            <button
              className='flex items-center font-josefin font-bold text-sm'
              onClick={() => {
                router.replace(SITE_URL);
                signOut();
              }}
              type='button'
            >
              Logout
              <SignOut className='ml-2' weight='bold' />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
});

AccountProfileUpdateForm.displayName = 'AccountProfileUpdateForm';
