import * as React from 'react';

import { Check } from '@phosphor-icons/react';
import clsx from 'clsx';
import { FieldErrors } from 'react-hook-form';

import { FormErrorMessage } from '@/components/common';

import s from './Checkbox.module.css';

export interface CheckboxProps extends React.ComponentPropsWithRef<'input'> {
  className?: string;
  errors?: FieldErrors;
  label: string;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ errors, label, className, type = 'checkbox', ...props }, ref) => {
    return (
      <>
        <label className={clsx('flex gap-4 items-center cursor-pointer leading-none', className)}>
          <input ref={ref} type={type} {...props} className={clsx(s.input, 'sr-only peer')} />
          <div className='border border-khaki-5 rounded-md w-6 h-6 relative text-khaki-1 peer-focus-visible:ring-4 peer-focus-visible:ring-purple/25'>
            <Check
              className={clsx(s.icon, 'w-4 h-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2')}
              weight='bold'
            />
          </div>
          {label}
        </label>
        <FormErrorMessage errors={errors} name={props.name as string} />
      </>
    );
  },
);

Checkbox.displayName = 'Checkbox';
