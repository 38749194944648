'use client';
import * as React from 'react';

import { CaretDown, Plus } from '@phosphor-icons/react';
import {
  Accordion as ReachAccordion,
  AccordionProps,
  AccordionButton as ReachAccordionButton,
  AccordionButtonProps as AccordionButtonProps,
} from '@reach/accordion';
import '@reach/accordion/styles.css';

import s from './Accordion.module.css';

export { AccordionItem, AccordionPanel } from '@reach/accordion';

export const Accordion = React.memo<AccordionProps>((props) => {
  return (
    <div className={s.root}>
      <ReachAccordion {...props} />
    </div>
  );
});

Accordion.displayName = 'Accordion';

export const AccordionButton = React.memo<AccordionButtonProps & { usePlus?: boolean }>(
  ({ children, usePlus, ...props }) => {
    return (
      <ReachAccordionButton {...props}>
        {children}
        {usePlus && <Plus className='w-6 h-6' />}
        {!usePlus && <CaretDown className='w-6 h-6' />}
      </ReachAccordionButton>
    );
  },
);

AccordionButton.displayName = 'AccordionButton';
