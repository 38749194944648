import * as React from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { X } from '@phosphor-icons/react';
import clsx from 'clsx';

import { Overlay } from '@/components/ui';

export type DrawerPosition = 'top' | 'left' | 'right' | 'bottom';

interface DrawerProps extends React.ComponentPropsWithoutRef<'div'> {
  open?: boolean;
  onClose: () => void;
  className?: string;
  position?: DrawerPosition | null;
}

export const Drawer = React.memo<DrawerProps>(({ open, onClose, children, position = 'left', className }) => {
  const isLeft = position === 'left';

  return (
    <Transition.Root as={React.Fragment} show={open}>
      <Dialog as='div' className='relative z-50' onClose={onClose}>
        <Overlay />
        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div
              className={clsx(
                'pointer-events-none fixed inset-y-0 flex max-w-full',
                isLeft ? 'left-0 pr-12' : 'right-0 pl-12',
              )}
            >
              <Transition.Child
                as={React.Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom={isLeft ? '-translate-x-full' : 'translate-x-full'}
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo={isLeft ? '-translate-x-full' : 'translate-x-full'}
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                  <div className={clsx('flex h-full flex-col overflow-y-scroll bg-white py-4 shadow-xl', className)}>
                    <button
                      aria-label='Close panel'
                      className='self-end justify-self-end mr-4 sm:mr-6 focus:outline-none'
                      onClick={onClose}
                      type='button'
                    >
                      <X aria-hidden='true' className='h-6 w-6 text-white' />
                    </button>
                    <div className='relative mt-6 flex-1 px-4 sm:px-6'>{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

Drawer.displayName = 'Drawer';
