import * as React from 'react';

import { useUser } from '@clerk/nextjs';
import { X } from '@phosphor-icons/react';
import Portal from '@reach/portal';
import clsx from 'clsx';
import NextImage from 'next/image';
import toast from 'react-hot-toast';
import { useFullscreen, useIdle, useKey, useLockBodyScroll, useToggle } from 'react-use';

import {
  AirplayButton,
  FastForwardButton,
  FullscreenButton,
  GCastButton,
  HlsTrackUpdateEventCallback,
  MuteButton,
  PlayButton,
  Player,
  PlayerEventCallback,
  PlayerProgressEventCallback,
  PlayerReadyEventCallback,
  RewindButton,
  SubtitlesButton,
  TimelineSlider,
  VolumeSlider,
} from '@/components/player';
import { Loading } from '@/components/ui';
import { useAnalyticsDispatch } from '@/context/analytics';
import { useGoogleCastWebSender } from '@/hooks/useGoogleCastWebSender';
import { useUrl } from '@/hooks/useUrl';
import { SITE_URL } from '@/lib/constants';
import { WorkoutSlim } from '@/types/28';
import { getUserMetadata } from '@/utils/auth/getUserMetadata';
import { imgixLoader } from '@/utils/images';
import { formatSeconds } from '@/utils/mux';
import { clamp } from '@/utils/number';
import { capitalize } from '@/utils/string';
import { trpc } from '@/utils/trpc';

import { WorkoutFivePointRating } from '../WorkoutFivePointRating';

import s from './WorkoutPlayer.module.css';

const idleEvents = ['mousemove', 'mousedown', 'touchstart', 'touchmove', 'touchend', 'keypress', 'wheel'];

export interface PlayerProps {
  playback: {
    muxPlaybackId: string;
    policy: string;
  };
  workout: WorkoutSlim;
  open?: boolean;
  onClose?: () => void;
}

export const WorkoutPlayer = React.memo<PlayerProps>(({ workout, playback, open, onClose }) => {
  const { user } = useUser();
  const { userId } = getUserMetadata(user);
  const url = useUrl();

  const videoContainerRef = React.useRef<HTMLDivElement | null>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const [playing, setPlaying] = React.useState(false);
  const [progress, setProgress] = React.useState({ loaded: 0, loadedSeconds: 0, played: 0, playedSeconds: 0 });
  const [muted, setMuted] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const [volume, setVolume] = React.useState(1);
  const [subtitleTrackId, setSubtitleTrackId] = React.useState<number>(-1);
  const [buffering, setBuffering] = React.useState(true);
  const [subtitleTracks, setSubtitleTracks] = React.useState<{ id: number; name: string; lang?: string }[] | null>(
    null,
  );

  const playbackId = playback.muxPlaybackId;

  const requiresToken = playback?.policy === 'signed';
  const tokenPlaybackId = requiresToken ? playbackId : undefined;

  const analyticsDispatch = useAnalyticsDispatch();

  const { data: dbUser } = trpc.users.byId.useQuery(
    { id: userId || '' },
    {
      enabled: !!userId,
    },
  );

  const { data: videoToken } = trpc.videos.token.useQuery(
    { playbackId: tokenPlaybackId as string, type: 'video' },
    {
      enabled: !!tokenPlaybackId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
  const { mutate } = trpc.workouts.watch.useMutation();

  const src = React.useMemo(() => {
    if (requiresToken && !videoToken) return;

    const url = new URL(`https://stream.mux.com/${playbackId}`);
    const params = new URLSearchParams();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    params.set('token', videoToken?.data.token!);
    url.search = params.toString();

    return url.toString();
  }, [playbackId, requiresToken, videoToken]);

  const { video, title, instructor, image, targetArea: workoutTargetArea, intensity: workoutIntensity } = workout;

  const targetArea = capitalize(workoutTargetArea?.replace(/_/g, ' ').toLowerCase());
  const intensity = capitalize(workoutIntensity?.toLowerCase());

  const { castActive, castPlaying, castMuted, castPlayPause, castMuteUnmute, castSeekTo, castStop } =
    useGoogleCastWebSender(src, 'application/vnd.apple.mpegurl', {
      onProgressChanged: (currentTime) => {
        if (videoRef.current) {
          const duration = videoRef.current.duration;
          setProgress((prev) => ({
            ...prev,
            playedSeconds: clamp(0, currentTime, duration),
            played: clamp(0, currentTime / duration, 1),
          }));
          videoRef.current.currentTime = currentTime;
        }
      },
      onMediaLoadingError: () => {
        toast.promise(
          new Promise((_, reject) => reject()),
          {
            loading: '',
            error: () => 'There was a problem loading media to ChromeCast',
            success: () => 'There was a problem loading media to ChromeCast',
          },
          { duration: 7000 },
        );
      },
      startingSeekPosition: videoRef.current ? Math.floor(videoRef.current.currentTime) : undefined,
      startingVolume: volume,
      debug: true,
      metadata: {
        images: image ? [{ url: `${SITE_URL}/${image.fileName}` }] : undefined,
        title,
      },
    });

  const heartbeatCallback = React.useCallback(
    (threshold: number) => {
      if (threshold >= 0.9) {
        analyticsDispatch({
          type: 'VIDEO_COMPLETED',
          payload: {
            category: 'Workout',
            dbUser: dbUser?.data,
            duration: Math.round(video?.duration ?? 0),
            workout: workout,
          },
        });
      }
    },
    [analyticsDispatch, dbUser, video?.duration, workout],
  );

  const onReady = React.useCallback<PlayerReadyEventCallback>(() => {
    if (videoRef.current && Math.floor(videoRef.current.currentTime) === 0) {
      const oldMuted = videoRef.current.muted;
      const restoreMuted = () => videoRef.current && (videoRef.current.muted = oldMuted);

      // try to play with current options
      // if it fails, mute and try playing again
      // if that fails, restore muted state and don't try playing again
      videoRef.current
        .play()
        .then(() => setPlaying(true))
        .catch(() => {
          if (videoRef.current) {
            videoRef.current.muted = true;
            setMuted(true);

            videoRef.current
              .play()
              .then(() => setPlaying(true))
              .catch(() => {
                if (videoRef.current) {
                  restoreMuted();
                  setPlaying(false);
                }
              });
          }
        });
    }
  }, []);

  const onPlayPause = React.useCallback(() => {
    setPlaying((prev) => !prev);
  }, []);

  const onDuration = React.useCallback<PlayerEventCallback>((event) => {
    setDuration((event.target as HTMLVideoElement).duration);
  }, []);

  const onVolume = React.useCallback<(value: number) => void>((value) => {
    const newVolume = Number(value.toFixed(2));
    setVolume(newVolume);
    setMuted(!newVolume);
  }, []);

  const onStart = React.useCallback<PlayerEventCallback>(() => {
    mutate({ id: workout.id });

    analyticsDispatch({
      type: 'VIDEO_STARTED',
      payload: {
        category: 'Workout',
        dbUser: dbUser?.data,
        duration: Math.round(video?.duration ?? 0),
        workout: workout,
        location: url.includes('/workouts/') ? 'Workout: Player' : 'Dashboard: Player',
      },
    });
  }, [mutate, workout, analyticsDispatch, dbUser, video?.duration, url]);

  const onEnded = React.useCallback<
    (event: React.SyntheticEvent<HTMLVideoElement, Event>, shouldCallTrackingEvent?: boolean) => void
  >(() => {
    setProgress((prev) => ({ ...prev, played: 1, playedSeconds: duration }));

    setPlaying(false);
  }, [duration]);

  const onProgress = React.useCallback<PlayerProgressEventCallback>((progress) => {
    setProgress(progress);
  }, []);

  const onSubtitleTrackIdChange = React.useCallback((event: React.BaseSyntheticEvent) => {
    setSubtitleTrackId(Number(event.target.value));
  }, []);

  const onSubtitleTrackUpdate = React.useCallback<HlsTrackUpdateEventCallback>((_, data) => {
    setSubtitleTracks(
      data.subtitleTracks.map((track) => ({
        id: track.id,
        name: track.name,
        lang: track.lang,
      })),
    );
  }, []);

  const onInitTrackSwitch = React.useCallback((trackId: number) => setSubtitleTrackId(trackId), []);

  const onSeekEnd = React.useCallback(
    (played: number) => {
      if (castActive) return castSeekTo({ type: 'exact', time: played });

      if (videoRef.current) {
        // TODO: Get better understanding of onDuration via state as it seems to be an unreliable way to get duration... returns 0 under certain conditions
        const duration = videoRef.current.duration;
        const newTime = played * duration;

        // Duration might be undefined if video manifest hasn't loaded. Could produce NaN.
        if (typeof newTime !== 'number') return;

        videoRef.current.currentTime = newTime;

        // The onProgress callback will handle this during playback
        if (!playing) {
          setProgress((prev) => ({
            ...prev,
            playedSeconds: clamp(0, newTime, duration), // clamp prevents over seeking
            played: clamp(0, newTime / duration, 1),
          }));
        }
      }
    },
    [castActive, playing, castSeekTo],
  );

  const onSeek = React.useCallback(
    (seconds: number) => {
      if (castActive && seconds < 0) return castSeekTo({ type: 'back', seconds: Math.abs(seconds) }, true);
      if (castActive && seconds > 0) return castSeekTo({ type: 'forward', seconds }, true);

      if (videoRef.current) {
        // TODO: Get better understanding of onDuration via state as it seems to be an unreliable way to get duration... returns 0 under certain conditions
        const duration = videoRef.current.duration;
        const newTime = videoRef.current.currentTime + seconds;
        videoRef.current.currentTime = newTime;
        // The onProgress callback will handle this during playback
        if (!playing) {
          setProgress((prev) => ({
            ...prev,
            playedSeconds: clamp(0, newTime, duration), // clamp prevents over seeking
            played: clamp(0, newTime / duration, 1),
          }));
        }
      }
    },
    [castActive, playing, castSeekTo],
  );

  const [show, toggleFullScreen] = useToggle(false);
  const fullscreen = useFullscreen(videoContainerRef, show, {
    onClose: () => toggleFullScreen(false),
    video: videoRef,
  });

  const heartbeatThreshold = React.useMemo(() => [0.2, 0.4, 0.6, 0.8, 0.9], []);
  const muxConfig = React.useMemo(
    () =>
      workout.video
        ? {
            env_key: process.env.NEXT_PUBLIC_MUX_ENV_KEY as string,
            player_name: 'Workout Player',
            meta: {
              video_id: workout.video.id,
              video_title: workout.video.title,
              video_series: workout.phasedays[0]?.phaseday.phase.name || '',
              video_duration: workout.video.duration || 0,
            },
          }
        : undefined,
    [workout],
  );

  const onBuffer = React.useCallback(() => setBuffering(true), [setBuffering]);
  const onBufferEnd = React.useCallback(() => setBuffering(false), [setBuffering]);

  const isIdle = useIdle(1500, false, idleEvents);
  const showControls = !isIdle || !playing || castActive || buffering;

  // Key board actions
  useKey(
    'Escape',
    (event) => {
      if (castActive) castStop();
      if (open && !event.metaKey && !event.ctrlKey) {
        event.preventDefault();
        onClose?.();
      }
    },
    {},
    [castActive],
  );

  useKey(
    'f',
    (event) => {
      if (open && !event.metaKey && !event.ctrlKey) {
        event.preventDefault();
        toggleFullScreen(!fullscreen);
      }
    },
    {},
    [fullscreen],
  );

  useKey(
    ' ',
    (event) => {
      if (open && !event.metaKey && !event.ctrlKey) {
        event.preventDefault();
        if (castActive) {
          castPlayPause();
          return;
        }
        onPlayPause();
      }
    },
    {},
    [castActive],
  );

  useKey(
    'ArrowLeft',
    (event) => {
      if (open && !event.metaKey && !event.ctrlKey) {
        event.preventDefault();
        onSeek(-10);
      }
    },
    {},
    [castActive],
  );

  useKey(
    'ArrowRight',
    (event) => {
      if (open && !event.metaKey && !event.ctrlKey) {
        event.preventDefault();
        onSeek(10);
      }
    },
    {},
    [castActive],
  );

  useKey(
    'm',
    (event) => {
      if (open && !event.metaKey && !event.ctrlKey) {
        event.preventDefault();
        if (castActive) {
          castMuteUnmute();
          return;
        }
        setMuted((prev) => !prev);
      }
    },
    {},
    [castActive],
  );

  useLockBodyScroll(open);

  return (
    <Portal>
      {open && workout.video && (
        <div
          className={`${s.root} fixed z-50 inset-0 bg-black text-khaki-1`}
          data-testid='workout-player-modal'
          ref={videoContainerRef}
          role='dialog'
        >
          <Player
            className='w-full h-full'
            controls={false}
            heartbeatCallback={heartbeatCallback}
            heartbeatThreshold={heartbeatThreshold}
            height='100%'
            muted={muted}
            muxConfig={muxConfig}
            onBuffer={onBuffer}
            onBufferEnd={onBufferEnd}
            onDuration={onDuration}
            onEnded={onEnded}
            onInitTrackSwitch={onInitTrackSwitch}
            onProgress={onProgress}
            onReady={onReady}
            onStart={onStart}
            onSubtitleTrackUpdate={onSubtitleTrackUpdate}
            playing={castActive ? false : playing}
            playsInline
            ref={videoRef}
            src={src}
            subtitleTrackId={subtitleTrackId}
            subtitlesOnStart={true}
            volume={volume}
            width='100%'
          />
          {castActive && (
            <div className='absolute top-0 font-josefin font-bold text-lg left-0 flex items-center justify-center inset-0 bg-black'>
              <span className='hidden lg:block'>Casting</span>
              {image && (
                <NextImage
                  alt={image.title}
                  className='object-cover'
                  fill
                  loader={imgixLoader}
                  sizes='100vw'
                  src={`/${image.fileName}`}
                />
              )}
            </div>
          )}
          <div
            className={clsx(
              'grid grid-rows-[max-content_auto_max-content_max-content] grid-cols-3 p-6 transition-opacity absolute inset-0 bg-black/70 lg:bg-transparent lg:bg-gradient-to-b lg:from-black/50 lg:to-black/70 lg:via-[transparent_20%,transparent_75%]',
              showControls ? 'opacity-100' : 'opacity-0',
            )}
          >
            <div className='flex justify-between items-start col-span-full'>
              <div className='flex flex-col gap-y-4 lg:flex-row lg:gap-x-8'>
                <div className='flex items-center gap-4 lg:bg-[#293139]/80 lg:rounded-lg overflow-hidden max-w-lg '>
                  <div className='w-10 h-10 lg:w-20 lg:h-20 shrink-0 relative'>
                    {image && (
                      <NextImage
                        alt={title}
                        className='rounded-full object-cover lg:rounded-none'
                        fill
                        loader={imgixLoader}
                        sizes='80px'
                        src={`/${image.fileName}`}
                      />
                    )}
                  </div>

                  <div className='lg:py-4 pr-6 lg:pr-8 overflow-hidden'>
                    <div className='font-josefin font-bold text-base  text-ellipsis whitespace-nowrap overflow-hidden'>
                      {title}
                    </div>
                    <div className='text-white/80 text-xs mt-1 gap-2 font-josefin hidden sm:flex'>
                      {instructor?.name} <span className='text-white/50'>&bull;</span> {targetArea}{' '}
                      <span className='text-white/50'>&bull;</span> {intensity}
                    </div>
                  </div>
                </div>
                <WorkoutFivePointRating color='white' workoutId={workout.id} />
              </div>
              <button
                data-testid='close-workout-player-modal-button'
                onClick={() => {
                  if (castActive) castStop();
                  onClose?.();
                }}
              >
                <X className='w-6 h-6' />
              </button>
            </div>

            {buffering && !castActive && (
              <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                <Loading />
              </div>
            )}

            <div
              className={clsx(
                'flex justify-center items-center col-span-full lg:col-span-1 lg:row-start-4 lg:col-start-2',
                {
                  'opacity-0 lg:opacity-100': buffering,
                },
              )}
            >
              <RewindButton onClick={() => onSeek(-10)} />
              <PlayButton
                className='mx-8'
                ended={progress.played === 1}
                onClick={() => (castActive ? castPlayPause() : setPlaying((prev) => !prev))}
                playing={castActive ? castPlaying : playing}
              />
              <FastForwardButton onClick={() => onSeek(10)} />
            </div>

            <div className='flex items-center lg:row-start-4 lg:col-start-1 text-sm'>
              <div className='flex group gap-4'>
                <MuteButton
                  isLowVolume={volume > 0 && volume < 0.6}
                  isMuted={castActive ? castMuted : muted}
                  onClick={() => (castActive ? castMuteUnmute() : setMuted((prev) => !prev))}
                />
                <VolumeSlider
                  className='hidden sm:block w-0 opacity-0 mr-0 group-hover:w-[56px] group-hover:opacity-100 group-hover:mr-6 transition-all duration-200'
                  onScrub={onVolume}
                  position={muted ? 0 : volume}
                />
              </div>
              <div className='ml-3 sm:ml-0'>
                {formatSeconds(progress.playedSeconds)} / {formatSeconds(duration)}
              </div>
            </div>

            <div className='flex items-center justify-end gap-6 col-start-3 lg:row-start-4 lg:col-start-3 leading-none'>
              <FullscreenButton isFullscreen={fullscreen} onClick={toggleFullScreen} />
              <GCastButton />
              <AirplayButton videoRef={videoRef} />
              <SubtitlesButton
                onChangeHandler={onSubtitleTrackIdChange}
                subtitleTracks={subtitleTracks}
                value={subtitleTrackId}
              />
            </div>

            <TimelineSlider
              className='col-span-full lg:row-start-3'
              duration={duration}
              onSeekEnd={onSeekEnd}
              playback={playback}
              progress={progress}
            />
          </div>
        </div>
      )}
    </Portal>
  );
});

WorkoutPlayer.displayName = 'WorkoutPlayer';
