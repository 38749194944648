import * as React from 'react';

import { CornersIn, CornersOut } from '@phosphor-icons/react';

interface FullscreenButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  isFullscreen: boolean;
}

export const FullscreenButton: React.FC<FullscreenButtonProps> = ({ isFullscreen, ...props }) => {
  return (
    <button
      {...props}
      className='hover:scale-110 transition-transform'
      title={isFullscreen ? 'exit fullscreen' : 'enter fullscreen'}
      type='button'
    >
      {isFullscreen ? <CornersIn className='w-6 h-6' /> : <CornersOut className='w-6 h-6' />}
    </button>
  );
};
