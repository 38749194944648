import * as React from 'react';

import { Airplay } from '@phosphor-icons/react';

import { useAppleAirplay } from '@/hooks/useAppleAirplay';

export const AirplayButton: React.FC<
  React.ComponentPropsWithoutRef<'button'> & { videoRef: React.MutableRefObject<HTMLVideoElement | null> }
> = ({ videoRef, ...props }) => {
  const { airplayAvailable, showAirplayPicker } = useAppleAirplay(videoRef);

  if (!airplayAvailable || !videoRef.current) return <></>;

  return (
    <button
      {...props}
      className='hover:scale-110 transition-transform'
      onClick={showAirplayPicker}
      title='airplay'
      type='button'
    >
      <Airplay className='w-6 h-6' />
    </button>
  );
};
